<template>
  <div class="box_10_f flex-col justify-end">
    <div v-if="showTag" class="section_11_f flex-col">
      <div class="text-group_5_f flex-col justify-between">
        <span class="text_47_f">共创智能时代&nbsp;共享智能成果</span>
        <span class="text_48_f">构建瓦房店工业互联网平台&nbsp;探索瓦房店轴承企业集群式发展模式</span>
      </div>
      <!-- <div class="text-wrapper_1_f flex-col">
       <span class="text_49_f">立即使用</span>
      </div> -->
    </div>
    <img class="image_6_f" referrerpolicy="no-referrer"
      src="./assets/img/psyb2b7jdowxxlj9hrdhp7s7t5p1z005462dc0b4-a23b-445e-bb23-a2c2bf36d4be.png" />
    <div class="group_14_f flex-row">
      <span class="paragraph_3_f">
        <a href="/lanhu_yungongchang">云工厂</a>
        <br />
        <a href="/lanhu_yunguanwang">云官网</a>
        <br />
        <a href="/lanhu_yunmingpian"> 云名片</a>
      </span>
      <span class="paragraph_4_f">
        <a href="/lanhu_yunditu">云地图</a>
        <br />
        <a href="/lanhu_yunbiaoshi">云标识</a>
        <br />
        <a href="/lanhu_yunhezuo">云合作</a>
      </span>
      <span class="paragraph_5_f">
        <a href="/lanhu_yunshangcheng">云商城</a>
        <br />
        <!-- /lanhu_yunchaoshi -->
        <a href="#">云超市</a>
        <br />
        <a href="/lanhu_yunjicai">云集采</a>
      </span>
      <div class="image-text_2_f flex-col justify-between">
        <img class="image_7_f" referrerpolicy="no-referrer"
          src="./assets/img/psktcjz2kk3mr827wm3gnav5gl5d0705m638fd7dfc-2a14-4cd4-a2d4-5ffb9243d8d5.png" />
        <span class="text-group_7_f">服务热线</span>
      </div>
      <div class="group_15_f flex-col"></div>
      <span class="paragraph_6_f">
        <a href="/lanhu_yungongqiu">云供求</a>
        <br />
        <a href="/lanhu_yunzhanting">云展厅</a>
        <br />
        <a href="/lanhu_yunwuliu">云物流</a>
      </span>
      <span class="paragraph_7_f">
        <a href="/lanhu_yunqianyue">云签约</a>
        <br />
        <a href="/lanhu_yunfuwu">云服务</a>
        <br />
        <a href="/lanhu_yunkeyan">云可研</a>
      </span>
      <span class="paragraph_8_f">
        <a href="/lanhu_yunsheji">云设计</a>
        <br />
        <a href="/lanhu_yunjinrong">云金融</a>
        <br />
        <a href="/lanhu_yunqiatan">云洽谈</a>
      </span>
      <span class="text_58_f"> <a href="/lanhu_yunchuangxinlianheti">云创新联合体</a></span>
    </div>
    <div class="text-wrapper_3_f flex-row justify-between">
      <span class="text_59_f">186-4082-2691</span>
      <span class="text_60_f">微信小程序</span>
    </div>
    <div class="text-wrapper_4_f flex-col">
      <span>Copyright © 2018-2024 jingyipt.com <a href="https://beian.mit.gov.cn">辽ICP备2023010580号</a></span>
    </div>
    <div class="group_16_f flex-col">
      <img src="./assets/img/wxApp.jpg" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showTag: Boolean
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
