<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="section_4 flex-col">
        <div class="group_4 flex-col justify-end">
          <div class="group_5 flex-row">
            <div class="group_6 flex-col">
              <el-row :gutter="1">
                <el-col :span="20">
                  <el-input v-model="query.orgName" style="width:100%" placeholder="请输入关键字" clearable
                    @change="loadData(true)"></el-input>
                </el-col>
                <el-col :span="4">
                  <el-button type="primary" size="medium" icon="el-icon-search" @click="loadData(true)"></el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="group_9 flex-col justify-between">
          <el-row :gutter="20">
            <el-col :span="12" v-for="(item, index) in list" :key="index">
              <div @click="openQrCode(item.identityQcCode)">
                <tenant-card :form-data="item"></tenant-card>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- <bottom-detector :loadingMore="loading" :noMore="list.length >= total" @arriveBottom="loadData" :distance="400" /> -->
        <div class="group_36 flex-row">
          <div class="text-group_25 flex-col justify-between">
            <span class="text_38">壹码通联</span>
            <span class="text_39">打造万物互联的智能世界！</span>
          </div>
        </div>
        <el-dialog :visible.sync="visible" width="250" @close="close" :modal="false">
          <div>
            <div style="text-align: center;margin-bottom: 0.8rem;font-size: medium;">请打开微信扫描下方二维码访问企业信息</div>
            <div style="text-align: center;"> <img :src="$url(qcCodeURL)" alt="请扫描小程序码访问" width="50%" height="50%" />
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- <fotter :showTag="true" /> -->
    </div>
  </div>
</template>
<script>
import tenantCard from "./tenantCard.vue"
import api from "./api"
// import bottomDetector from '@/views/components/bottomDetector/index.vue'
export default {
  components: {
    tenantCard,
  },
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      visible: false,
      qcCodeURL: '',
      query: {
        // 三取一
        pageSize: 12,
        pageNum: 1,
        operateType: 'desc'
      }
    };
  },
  mounted() {
    this.loadData(true)
  },
  methods: {
    close() {
      this.visible = false
    },
    loadData(flg) {
      if (flg) {
        this.query.pageNum = 1
      } else {
        this.query.pageNum++
      }
      this.loading = true
      api.list(this.query).then((res) => {
        if (res.code === 200) {
          if (flg) {
            this.list = res.rows
            this.total = res.total
          } else {
            this.list = [...this.list, ...res.rows]
            this.total = res.rows
          }
          this.loading = false

        }
      })
    },
    openQrCode(qcCodeURL) {
      if (qcCodeURL) {
        this.visible = true
        this.qcCodeURL = qcCodeURL
      } else {
        this.$message.error('该企业暂无标识解析码！')
      }
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />