import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_huaban1 from '../views/lanhu_huaban1/index.vue'
import lanhu_huaban2 from '../views/lanhu_huaban2/index.vue'
import lanhu_huaban3 from '../views/lanhu_huaban3/index.vue'
import lanhu_huaban4 from '../views/lanhu_huaban4/index.vue'
import lanhu_huaban5 from '../views/lanhu_huaban5/index.vue'
import lanhu_yunfuwu from '../views/lanhu_yunfuwu/index.vue'
import lanhu_identity from '../views/lanhu_identity/index.vue'
import lanhu_identity_tenant from '../views/lanhu_identity_tenant/index.vue'
import lanhu_identity_product from '../views/lanhu_identity_product/index.vue'
import lanhu_identity_jinpu from '../views/lanhu_identity_jinpu/index.vue'
import lanhu_identity_tenant_jinpu from '../views/lanhu_identity_tenant_jinpu/index.vue'
import lanhu_identity_product_jinpu from '../views/lanhu_identity_product_jinpu/index.vue'
import lanhu_yunwuliu from '../views/lanhu_yunwuliu/index.vue'
import lanhu_yunqiatan from '../views/lanhu_yunqiatan/index.vue'
import lanhu_guanyuwomen from '../views/lanhu_guanyuwomen/index.vue'
import lanhu_yungongqiu from '../views/lanhu_yungongqiu/index.vue'
import lanhu_yunbiaoshi from '../views/lanhu_yunbiaoshi/index.vue'
import lanhu_yunjicai from '../views/lanhu_yunjicai/index.vue'
import lanhu_yunditu from '../views/lanhu_yunditu/index.vue'
import lanhu_yunguanwang from '../views/lanhu_yunguanwang/index.vue'
import lanhu_yunjinrong from '../views/lanhu_yunjinrong/index.vue'
import lanhu_yunkeyan from '../views/lanhu_yunkeyan/index.vue'
import lanhu_yunqianyue from '../views/lanhu_yunqianyue/index.vue'
import lanhu_yunsheji from '../views/lanhu_yunsheji/index.vue'
import lanhu_yunzhanting from '../views/lanhu_yunzhanting/index.vue'
import lanhu_yunmingpian from '../views/lanhu_yunmingpian/index.vue'
import lanhu_yunhezuo from '../views/lanhu_yunhezuo/index.vue'
import lanhu_yunchuangxinlianheti from '../views/lanhu_yunchuangxinlianheti/index.vue'
import lanhu_yungongchang from '../views/lanhu_yungongchang/index.vue'
import lanhu_yunshangcheng from '../views/lanhu_yunshangcheng/index.vue'

Vue.use(VueRouter)

const routes = [
  /** 首页 */
  {
    path: '/',
    name: '/',
    component: lanhu_huaban1
  },
  {
    path: '/lanhu_huaban2',
    name: 'lanhu_huaban2',
    component: lanhu_huaban2
  },
  {
    path: '/lanhu_huaban3',
    name: 'lanhu_huaban3',
    component: lanhu_huaban3
  },
  {
    path: '/lanhu_huaban4',
    name: 'lanhu_huaban4',
    component: lanhu_huaban4
  },
  {
    path: '/lanhu_huaban5',
    name: 'lanhu_huaban5',
    component: lanhu_huaban5
  },
  /** 云服务 */
  {
    path: '/lanhu_yunfuwu',
    name: 'lanhu_yunfuwu',
    component: lanhu_yunfuwu
  },
  /** 云物流 */
  {
    path: '/lanhu_yunwuliu',
    name: 'lanhu_yunwuliu',
    component: lanhu_yunwuliu
  },
  /** 云洽谈 */
  {
    path: '/lanhu_yunqiatan',
    name: 'lanhu_yunqiatan',
    component: lanhu_yunqiatan
  },
  /** 云供求 */
  {
    path: '/lanhu_yungongqiu',
    name: 'lanhu_yungongqiu',
    component: lanhu_yungongqiu
  },
  /** 云标识 */
  {
    path: '/lanhu_yunbiaoshi',
    name: 'lanhu_yunbiaoshi',
    component: lanhu_yunbiaoshi
  },
  /** 云集采 */
  {
    path: '/lanhu_yunjicai',
    name: 'lanhu_yunjicai',
    component: lanhu_yunjicai
  },
  /** 云地图 */
  {
    path: '/lanhu_yunditu',
    name: 'lanhu_yunditu',
    component: lanhu_yunditu
  },
  /** 云官网 */
  {
    path: '/lanhu_yunguanwang',
    name: 'lanhu_yunguanwang',
    component: lanhu_yunguanwang
  },
  /** 云金融 */
  {
    path: '/lanhu_yunjinrong',
    name: 'lanhu_yunjinrong',
    component: lanhu_yunjinrong
  },
  /** 云合作 */
  {
    path: '/lanhu_yunhezuo',
    name: 'lanhu_yunhezuo',
    component: lanhu_yunhezuo
  },
  /** 云可研 */
  {
    path: '/lanhu_yunkeyan',
    name: 'lanhu_yunkeyan',
    component: lanhu_yunkeyan
  },
  /** 云名片 */
  {
    path: '/lanhu_yunmingpian',
    name: 'lanhu_yunmingpian',
    component: lanhu_yunmingpian
  },
  /** 云设计 */
  {
    path: '/lanhu_yunsheji',
    name: 'lanhu_yunsheji',
    component: lanhu_yunsheji
  },
  /** 云展厅 */
  {
    path: '/lanhu_yunzhanting',
    name: 'lanhu_yunzhanting',
    component: lanhu_yunzhanting
  },
  /** 云签约 */
  {
    path: '/lanhu_yunqianyue',
    name: 'lanhu_yunqianyue',
    component: lanhu_yunqianyue
  },
  /** 云创新联合体 */
  {
    path: '/lanhu_yunchuangxinlianheti',
    name: 'lanhu_yunchuangxinlianheti',
    component: lanhu_yunchuangxinlianheti
  },
  /** 关于我们 */
  {
    path: '/lanhu_guanyuwomen',
    name: 'lanhu_guanyuwomen',
    component: lanhu_guanyuwomen
  },
  /** 云工厂 */
  {
    path: '/lanhu_yungongchang',
    name: 'lanhu_yungongchang',
    component: lanhu_yungongchang
  },
  /** 云商城 */
  {
    path: '/lanhu_yunshangcheng',
    name: 'lanhu_yunshangcheng',
    component: lanhu_yunshangcheng
  },
  /** 标识解析平台 企业列表页 */
  {
    path: '/lanhu_identity',
    name: 'lanhu_identity',
    component: lanhu_identity
  },
  /** 标识解析平台 企业详情页 */
  {
    path: '/lanhu_identity_tenant',
    name: 'lanhu_identity_tenant',
    component: lanhu_identity_tenant
  },
  /** 标识解析平台 产品详情页 */
  {
    path: '/lanhu_identity_product',
    name: 'lanhu_identity_product',
    component: lanhu_identity_product
  },
  /** 标识解析平台 企业列表页 */
  {
    path: '/lanhu_identity_jinpu',
    name: 'lanhu_identity_jinpu',
    component: lanhu_identity_jinpu
  },
  /** 标识解析平台 企业详情页 */
  {
    path: '/lanhu_identity_tenant_jinpu',
    name: 'lanhu_identity_tenant_jinpu',
    component: lanhu_identity_tenant_jinpu
  },
  /** 标识解析平台 产品详情页 */
  {
    path: '/lanhu_identity_product_jinpu',
    name: 'lanhu_identity_product_jinpu',
    component: lanhu_identity_product_jinpu
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return {
      x: 0,
      y: 0
    }
  }
})

export default router