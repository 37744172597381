<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="section_4 flex-col">
        <div class="group_9 flex-col justify-between">
          <el-row :gutter="20">
            <el-col :span="18">
              <div>
                <div class="tenant_card">
                  <product-card :form-data="product"></product-card>
                </div>
                <div class="group_14_p flex-col">
                  <span class="text_9_p">产品参数</span>
                  <div class="section_6_p flex-col">
                    <div class="box_1_p flex-col"></div>
                  </div>
                  <div class="text-wrapper_5_p flex-row">
                    <el-row style="width:100%;">
                      <el-col v-for="item in product.bizProductParamList" :key="item.id" :span="8">
                        <div>
                          <span class="paragraph_1_p">{{ item.name }}</span>
                          <span class="paragraph_2_p">{{ item.value }}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <span class="text_10_p">产品详情</span>
                  <div class="section_7_p flex-col">
                    <div class="section_8_p flex-col"></div>
                  </div>
                  <div v-html="$richUrl(product.content)" style="padding: 0rem 1rem 0 1rem;"></div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="card_box_10 flex-col">
                <div class="card_text-wrapper_3 flex-col">
                  <span class="card_text_8">壹码通联</span>
                </div>
                <div class="card_image-wrapper_2 flex-col">
                  <img class="card_image_3" referrerpolicy="no-referrer" :src="$url(tenant.qcCode)" />
                </div>
                <span class="card_text_9">微信扫一扫浏览企业</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import productCard from "./productCard.vue"
import api from "./api"
export default {
  components: {
    productCard
  },
  data() {
    return {
      product: {},
      tenant: {},
      loading: false
    };
  },
  mounted() {
    this.tenant = this.$route.query.tenant
    this.loadData()
  },
  methods: {
    loadData() {
      this.getProductInfos()
    },
    getProductInfos() {
      this.loading = true
      this.product.id = this.$route.query.productId
      api.getProductInfo(this.product.id).then((res) => {
        if (res.code === 200) {
          this.product = res.data
          this.loading = false
        }
      })
    }

  }

};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
<style scoped>
::v-deep img {
  max-width: 100%;
  max-height: 100%;
}
</style>