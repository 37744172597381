<template>
    <div class="box_2 flex-row">
        <div class="image-wrapper_3 flex-col">
            <el-image class="image_2" referrerpolicy="no-referrer" :src="$urlIdentity(formData.logo)">
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
        </div>
        <span>
            <div class="text_28"> {{ formData.orgName }}</div>
            <div class="text_29"> {{ formData.orgAddr }}</div>
            <div class="button_1">
                <el-button class="button_30" type="primary" plain size="small" @click="to">企业介绍</el-button>
            </div>
        </span>
        <div class="icon_01">
            <div class="label_2">

            </div>
            <div class="text_Z1">手机扫码</div>
        </div>

    </div>
</template>
  
<script>
import router from "@/router";
export default {
    name: 'websiteCard',
    props: {
        formData: {},
    },
    methods: {
        to() {
            router.push({ path: '/lanhu_identity_tenant_jinpu', query: { tenantId: this.formData.id } })
        }
    }
}
</script>
<style scoped lang="css" src="./assets/index.rem.css" />