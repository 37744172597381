<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="section_4 flex-col">
        <div class="group_9 flex-col justify-between">
          <el-row :gutter="20">
            <el-col :span="18">
              <div>
                <div class="tenant_card">
                  <tenant-card :form-data="tenant"></tenant-card>
                </div>
                <div class="tenant_info">
                  <el-tabs v-model="tabName" @tab-click="loadData()" style="margin-left:0.614rem;">
                    <el-tab-pane label="企业简介" name="企业简介">
                      <div>
                        <div v-if="tenant.video">
                          <video :src="tenant.video" style="width:6rem; height:6rem;" controls></video>
                        </div>
                        <div v-html="$richUrl(tenant.introduction)" style="width:90%;"></div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="产品列表" name="产品列表">
                      <el-row>
                        <el-col :span="6">
                          <div style="border: 2px solid rgba(234, 234, 234, 1);width:6rem;height:14rem;margin-top:0.2rem;">
                            <el-tree :data="categoryList" :props="defaultProps" accordion @node-click="handleNodeClick">
                            </el-tree>
                          </div>
                        </el-col>
                        <el-col :span="18">
                          <div class="image_box" v-if="productList.length > 0">
                            <div class="block" v-for="item in productList" :key="item.id" @click="to(item.id)">
                              <el-image style="width: 5rem; height: 5rem" :src="$urlIdentity(item.mainPicture)"
                                fit="cover">
                                <div slot="error" class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                              </el-image>
                              <span class="demonstration">
                                <el-tooltip v-if="item.name.length > 8" class="item" effect="dark" :content="item.name"
                                  placement="top">
                                  <div style="width:5rem;padding: 0.2rem 0;">{{ item.name.substring(0, 8) + '...' }}</div>
                                </el-tooltip>
                                <div v-else style="width:5rem;padding: 0.2rem 0;">{{ item.name }}</div>
                              </span>
                            </div>
                          </div>
                          <div v-if="productList.length > 0" class="pagination">
                            <el-pagination @current-change="handleCurrentChange" :current-page="queryParams.pageNum"
                              :page-size="queryParams.pageSize" background layout="total, prev, pager, next"
                              :total="total">
                            </el-pagination>
                          </div>
                        </el-col>
                      </el-row>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="card_box_10 flex-col">
                <div class="card_text-wrapper_3 flex-col">
                  <span class="card_text_8">壹码通联</span>
                </div>
                <div class="card_image-wrapper_2 flex-col">
                  <img class="card_image_3" referrerpolicy="no-referrer" :src="$url(tenant.qcCode)" />
                </div>
                <span class="card_text_9">微信扫一扫浏览企业</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tenantCard from "./tenantCard.vue"
import api from "./api"
export default {
  components: {
    tenantCard
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      currentCategoryId: null,
      productList: [],
      categoryList: [],
      tabName: '企业简介',
      tenant: { id: 106 },
      loading: false,
      total: 0,
      queryParams: {
        pageSize: 10,
        pageNum: 1
      }
    };
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.tabName === '企业简介') {
        this.getTenantInfos()
      } else {
        this.getCategories()
      }
    },
    getTenantInfos() {
      this.loading = true
      this.tenant.id = this.$route.query.tenantId
      api.getTenantInfo(this.tenant.id).then((res) => {
        if (res.code === 200) {
          this.tenant = res.data
          this.loading = false
        }
      })
    },
    getCategories() {
      api.listCategory({ tenantId: this.tenant.id }).then((res) => {
        if (res.code === 200) {
          this.categoryList = this.$handleTree(res.rows)
          this.currentCategoryId = this.categoryList[0].id
          this.getProducts()
        }
      })
    },
    getProducts() {
      this.loading = true
      this.queryParams.tenantId = this.tenant.id
      this.queryParams.categoryId = this.currentCategoryId
      api.listProduct(this.queryParams).then((res) => {
        if (res.code === 200) {
          this.total = res.total
          this.productList = res.rows
          this.loading = false
        }
      })

    },
    handleNodeClick(data) {
      console.log(data)
      this.currentCategoryId = data.id
      this.getProducts()
    },
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getProducts()
    },
    to(productId) {
      this.$router.push({ path: '/lanhu_identity_product_jinpu', query: { productId, tenant: this.tenant } })
    }
  }

};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />
<style scoped>
::v-deep img {
  max-width: 100%;
  max-height: 100%;
}
</style>