<template>
  <div class="page flex-col">
    <div class="box_1 flex-row">
      <div class="section_1 flex-col">
        <div class="section_2 flex-col">
          <div class="box_2 flex-row">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/psi1czfk34n549nqb61wk9t8rqr9ee574b196fed5a-e9db-4c84-902e-345673d20cff.png"
            />
            <span class="text_1">云服务</span>
          </div>
          <span class="text_2">
            集结万名业内专家提供云咨询服务，制定技术、生产等环节专项解决方案，建立区域维修中心，对企业做好精准服务
          </span>
          <div class="image-text_1 flex-row justify-between">
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/ps19oh6ychwco7nbsjlgz92665ist0ugujkf7eed1b8-7673-4fa9-959d-fd2b5b122626.png"
            />
            <span class="text-group_1">立即探索</span>
          </div>
        </div>
        <div class="section_3 flex-col">
          <span class="text_3">云设计</span>
          <span class="text_4">产品&nbsp;工艺&nbsp;管理&nbsp;售后全方位运营链设计</span>
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="./assets/img/psa5f7l7ya0aha7fcap3jaam4p10y5mb9l0819b288-b0cd-436c-b499-f35079938654.png"
          />
          <div class="image-wrapper_1 flex-row">
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/psr6j0pal51wqtdlwsqis1gu4bbjj7zpuh4608b848-a504-4627-b0c6-b98987571591.png"
            />
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/psm8barwu3tzcmqgha0wmmdfyovxcgce9mma5c44827-febd-4a2c-8a78-a0941dcc7596.png"
            />
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/pscigvvxbxemoj2jsndwd1gp05s8gtdyugn506a74d79-a147-491b-93c1-454973fec04c.png"
            />
          </div>
          <div class="box_3 flex-row">
            <div class="image-text_2 flex-col justify-between">
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="./assets/img/psqxgp7bxfesbaz1po6r8m4nrz1uaijxh9ja169d2f3-375b-4e5e-8ee0-3fb477975724.png"
              />
              <span class="text-group_2">云可研</span>
            </div>
            <div class="image-text_3 flex-col justify-between">
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="./assets/img/psqyeezilqnaqe2q1khipfua4n1q9yzpv6954663a2-024e-44d1-8fec-77a45834a9f5.png"
              />
              <span class="text-group_3">云设计</span>
            </div>
            <div class="image-text_4 flex-col justify-between">
              <img
                class="label_6"
                referrerpolicy="no-referrer"
                src="./assets/img/psaxuz6yjiamaqpxvj2wlk684ne0qm7pnao370c1404-9424-4735-a2f9-6e6765d361ae.png"
              />
              <span class="text-group_4">云金融</span>
            </div>
            <div class="image-text_5 flex-col justify-between">
              <img
                class="label_7"
                referrerpolicy="no-referrer"
                src="./assets/img/psphn5b2mgv98fgagqxzsazlzid1yt2krgc3d4347de-4e5e-4fce-a2ac-fc9ccdec6187.png"
              />
              <span class="text-group_5">云洽谈</span>
            </div>
          </div>
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/pssndnbq428bopv2s277spfp7uib5h99d39827b2-8ac2-4b88-aa86-7c263d818791.png"
          />
        </div>
        <div class="section_4 flex-col">
          <div class="group_1 flex-row">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/psoityyu6tblns0gl0v5wxsvr6936ejcm1409968b-a3d7-468e-83d9-127c5a7b450d.png"
            />
            <span class="text_5">首页&nbsp;云工厂&nbsp;云商城&nbsp;云服务&nbsp;关于我们</span>
            <span class="text_6">登录&nbsp;&nbsp;|&nbsp;&nbsp;注册</span>
          </div>
          <div class="group_2 flex-col"><div class="section_5 flex-col"></div></div>
        </div>
      </div>
      <div class="section_6 flex-col justify-end">
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/pse7lmxy6ndwoyejnxno5vmj7qgenityhi8d07d1fce-fbc7-44d2-a7dc-787c30cbd168.png"
        />
        <div class="section_7 flex-row">
          <span class="paragraph_1">
            云订单
            <br />
            云创新联合体
            <br />
            云合作
          </span>
          <span class="paragraph_2">
            云合作
            <br />
            云物流
          </span>
          <span class="paragraph_3">
            云金融
            <br />
            云服务
          </span>
          <div class="section_8 flex-col justify-between">
            <div class="image-wrapper_2 flex-col">
              <img
                class="label_8"
                referrerpolicy="no-referrer"
                src="./assets/img/psiddo07dokexy4yyfozci0wix84c4buel682e26e8-e847-496f-968d-545e077099a4.png"
              />
            </div>
            <span class="text_7">服务热线</span>
          </div>
          <div class="section_9 flex-col"></div>
          <span class="paragraph_4">
            云可研
            <br />
            云设计
            <br />
            云官网
          </span>
          <span class="paragraph_5">
            云名片
            <br />
            云地图
            <br />
            云集采
          </span>
          <span class="paragraph_6">
            云工厂
            <br />
            云标识
            <br />
            云展厅
          </span>
          <span class="paragraph_7">
            云供求
            <br />
            云洽谈
            <br />
            云商城
          </span>
        </div>
        <div class="text-wrapper_1 flex-row justify-between">
          <span class="text_8">400-000-0000</span>
          <span class="text_9">微信公众号</span>
        </div>
        <div class="text-wrapper_2 flex-col">
          <span>Copyright © 2018-2024 jingyipt.com <a href="https://beian.mit.gov.cn">辽ICP备2023010580号</a></span>
        </div>
        <div class="section_10 flex-col"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />